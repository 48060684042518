import React from 'react'
import { AuthContext } from '@adludio/react-sso/dist'
import { useContext } from 'react'

import Login from './Login';

// This ignore is here, because I can't hint to Typescript that this app is actually 
// there and is working.
// @ts-ignore
import AdludioAdmin from '@adludio/adludio-admin';
import { RouteComponentProps } from '@reach/router';

export default function Protected({ ssoFEURL, env }: RouteComponentProps & { ssoFEURL: string, env: string }){
  const { auth } = useContext(AuthContext);
  const isAuth = auth.authStatus === 'success';

  if(isAuth) {
    return <div className="_demoAppWrapper">
      <div className="_demoHeaderWrapper" />
      <div className="_demoSideNavWrapper" />
      <div className="_demoContentArea">
        <AdludioAdmin env={env} />
      </div>
    </div>
  } else {
    return <Login ssoFEURL={ssoFEURL} />
  }
}
