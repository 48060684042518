"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateEmail = void 0;

var validateEmail = email => {
  var validation = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validation.test(email);
};

exports.validateEmail = validateEmail;