import React from 'react';

import { AuthProvider } from '@adludio/react-sso/dist';
import { Router, Redirect } from '@reach/router'
import { ThemeProvider } from '@adludio/components/dist/Theme';

import Protected from 'Protected';

import "./app.scss"

// Simplified because only one env
const env = process.env.REACT_APP_BUILD_ENV ?? "development"
const envPrefix = (env === 'development' ? 'dev' : 'testing');

const App: React.FC = () => {
  return (
    <AuthProvider ssoBEURL={`//${envPrefix}.api.sso.adludio.com`}>
      <ThemeProvider>
      <Router>
        <Protected path='admin/*' env={env} ssoFEURL={`//${envPrefix}.sso.adludio.com`}/>
        <Redirect default from='/' to='admin' noThrow />
      </Router>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App;
