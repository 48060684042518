"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialPermissions = initialPermissions;
exports.checkedPermissions = checkedPermissions;

function initialPermissions(permissions, currentPermissions) {
  return permissions.reduce((final, permission) => {
    if (currentPermissions.find(p => p.name === permission.name)) {
      final[permission.name] = true;
    } else {
      final[permission.name] = false;
    }

    return final;
  }, {});
}

function checkedPermissions(permissionState, allPermissions) {
  return allPermissions.filter((_ref) => {
    var {
      name
    } = _ref;
    return !!permissionState[name];
  });
}