import React from 'react';
import { H2, Body1 , Link, Button, Grid } from '@adludio/components';
import { encodePayload } from './Util/payloadActions';

const Login = (props: { ssoFEURL: string }) => {
  const { ssoFEURL } = props;
  const payload = encodePayload({ url: window.location.href, payload: {} });
  return (
    <Grid container className="_demoAppWrapper" direction="column" alignContent="center" alignItems="center">
      <div className="_demoHeaderWrapper" />
      <Grid xs={12} container direction="column" item alignItems="center">
        <H2>You are not logged in.</H2>
        <Body1>Click <Link href={`//${ssoFEURL}/logIn?payload=${payload}`}><Button variant="contained" size="large" >here</Button></Link> to login.</Body1 >
      </Grid>
    </Grid>
  );
};

export default Login;
