"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNull = void 0;

var isNull = arg => {
  return arg === null;
};

exports.isNull = isNull;