"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFilter = void 0;

var createFilter = (value, column) => {
  return arrItem => (column ? arrItem[column] : arrItem) === value;
};

exports.createFilter = createFilter;