"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CompanyInterface", {
  enumerable: true,
  get: function get() {
    return _CompanyInterface.default;
  }
});
Object.defineProperty(exports, "Home", {
  enumerable: true,
  get: function get() {
    return _Home.default;
  }
});
Object.defineProperty(exports, "UserInterface", {
  enumerable: true,
  get: function get() {
    return _UserInterface.default;
  }
});
Object.defineProperty(exports, "RolesInterface", {
  enumerable: true,
  get: function get() {
    return _RolesInterface.default;
  }
});
Object.defineProperty(exports, "PermissionsInterface", {
  enumerable: true,
  get: function get() {
    return _PermissionsInterface.default;
  }
});
Object.defineProperty(exports, "PoliciesInterface", {
  enumerable: true,
  get: function get() {
    return _PoliciesInterface.default;
  }
});

var _CompanyInterface = _interopRequireDefault(require("./CompanyInterface"));

var _Home = _interopRequireDefault(require("./Home"));

var _UserInterface = _interopRequireDefault(require("./UserInterface"));

var _RolesInterface = _interopRequireDefault(require("./RolesInterface"));

var _PermissionsInterface = _interopRequireDefault(require("./PermissionsInterface"));

var _PoliciesInterface = _interopRequireDefault(require("./PoliciesInterface"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }