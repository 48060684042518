interface Payload {
    url: string,
    payload: object;
}

export const encodePayload = (payload: Payload): string => {
  return btoa(JSON.stringify(payload));
};

export const decodePayload = (payload: string): Payload => {
  return JSON.parse(atob(payload));
};
