"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createActionCheckboxes = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var createActionCheckboxes = (services, policy, setPolicy) => {
  var handleCheckbox = event => {
    var existingServices = policy.services;
    policy.services.includes(event.target.value) ? existingServices.splice(existingServices.indexOf(event.target.value), 1) : existingServices.push(event.target.value);
    setPolicy(_objectSpread(_objectSpread({}, policy), {}, {
      services: existingServices
    }));
  }; // This function is temporary until we have a serviceActions endpoint where we can get all.


  var createActionsObj = () => {
    var actionsObj = {}; // Map through services to and get an array of servicesActions. Then set them on the actionsObj.

    services.forEach((_ref) => {
      var {
        services: servicesArray
      } = _ref;
      var actionsArray = servicesArray.map((_ref2) => {
        var {
          actions,
          name
        } = _ref2;
        return actions.flatMap(action => ({
          action,
          serviceName: name
        }));
      });
      var upperServiceName;
      var permissionArray = actionsArray.flatMap(action => action.map((_ref3) => {
        var {
          action: actName,
          serviceName
        } = _ref3;
        upperServiceName = serviceName === 'demo' ? 'demos' : serviceName;
        return "adl:".concat(upperServiceName, ":").concat(actName);
      }));
      actionsObj[upperServiceName] = permissionArray;
    });
    return actionsObj;
  };

  var actions = createActionsObj(); // Temporary until we have a serviceActions endpoint where we can get all.

  var actionInputs = Object.keys(actions).map(key => {
    return {
      name: key,
      label: key,
      checkboxes: actions[key].map(action => {
        return {
          id: action,
          name: action,
          label: action,
          value: policy.services.includes(action),
          onchange: handleCheckbox
        };
      })
    };
  });
  return actionInputs;
};

exports.createActionCheckboxes = createActionCheckboxes;