"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSSOGet;

var _react = require("react");

var _Store = require("../../Store");

var _dist = require("@adludio/react-sso/dist");

function useSSOGet(endpoint) {
  var {
    config: {
      ssoBEURL
    }
  } = (0, _react.useContext)(_Store.AdminContext);
  var {
    auth
  } = (0, _react.useContext)(_dist.AuthContext);
  var [data, setData] = (0, _react.useState)();

  var refetch = () => {
    fetch("".concat(ssoBEURL, "/").concat(endpoint), {
      headers: {
        Authorization: "Bearer ".concat(auth.authToken)
      }
    }).then(res => res.json()).then((_ref) => {
      var {
        data: dataFromRes,
        error
      } = _ref;

      if (error) {
        throw new Error(error.message);
      } else {
        setData(dataFromRes);
      }
    }).catch(console.log);
  };

  (0, _react.useEffect)(() => {
    refetch(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.authToken, endpoint, ssoBEURL]);
  return [data, refetch];
}