"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDropdownValues = void 0;

var createDropdownValues = data => {
  return data.map(obj => ({
    label: obj.name,
    value: obj.id,
    key: obj.id
  }));
};

exports.createDropdownValues = createDropdownValues;