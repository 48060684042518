"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSSOSubmit;

var _dist = require("@adludio/react-sso/dist");

var _react = require("react");

var _Store = require("../../Store");

function useSSOSubmit(endpoint, _ref, callback) {
  var {
    method,
    body
  } = _ref;
  var {
    config: {
      ssoBEURL
    }
  } = (0, _react.useContext)(_Store.AdminContext);
  var {
    auth
  } = (0, _react.useContext)(_dist.AuthContext);
  return e => {
    e.preventDefault();
    fetch("".concat(ssoBEURL, "/").concat(endpoint), {
      method,
      headers: {
        Authorization: "Bearer ".concat(auth.authToken),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(res => res.json()).then(callback).catch(console.log);
  };
}